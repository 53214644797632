<template>
    <div class="row justify-content-center">
        <div class="col-lg-12">
            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                <ul class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-around product-info-tab border-bottom-0" id="pills-tab" role="tablist">
                    <li class="list-inline-item active"><a class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase active" href="#navtabs0" data-toggle="tab">Detail</a></li>
                    <li class="list-inline-item"><a class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#navtabs1" data-toggle="tab">Questions</a></li>
                    <li class="list-inline-item"><a class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#navtabs2" data-toggle="tab">Add Test</a></li>
                </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="navtabs0">
                    <detail></detail>
                </div>
                <div class="tab-pane fade" id="navtabs1">
                    <quest :newData="newData"></quest>
                </div>
                <div class="tab-pane fade" id="navtabs2">
                    <add @new-data="sendNewData"></add>
                </div>
            </div>
        </div>
    </div>

</template>
<script>

import Detail from './Detail/Detail.vue'
import Quest from './Detail/Quest.vue'
import Add from './Detail/Add.vue'
export default {
    components:{
        Detail,
        Quest,
        Add,
    },
    data(){
        return {
          newData: 0
        }
    },
    methods:{
        sendNewData(){
          this.newData += 1
        }
    }
};
</script>