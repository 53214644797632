<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4 mb-3">
        <div class="row" v-if="!isLoad">
            <div class="col-12" v-if="questionnaire">
                <table class="table">
                    <tbody>
                        <tr>
                            <td class="border-0">
                                <p class="font-xs fw-600 text-grey-900 mb-0"><i class="fas fa-tasks text-warning mr-2"></i>Questionnaire Close Name</p>
                                <span class="font-xss fw-500 text-grey-700">{{questionnaire.title}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="border-0">
                                <p class="font-xs fw-600 text-grey-900 mb-0"><i class="fas fa-audio-description text-warning mr-2"></i>Description</p>
                                <span class="font-xss fw-500 text-grey-700">{{questionnaire.description}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="border-0">
                                <p class="font-xs fw-600 text-grey-900 mb-0"><i class="fas fa-chalkboard-teacher text-warning mr-2"></i>Created By</p>
                                <span class="font-xss fw-500 text-grey-700">{{questionnaire.created.name}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="border-0">
                                <p class="font-xs fw-600 text-grey-900 mb-0"><i class="fas fa-globe text-warning mr-2"></i>Publish</p>
                                <span v-if="questionnaire.publish" class="badge badge-success p-1">Publish</span><span v-else class="badge badge-danger p-1">Unpublish</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12 text-center" v-else>
                <img :src="'/images/empty.png'" alt="" width="300">
                <h1 class="text-muted">Data don't exist</h1>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-12 text-center py-3">
                <div class="my-3">
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return{
            id: this.$route.params.idQuestionnaire,
            slug: this.$route.params.idCourse,
            isLoad: true,
            questionnaire: []
        }
    },
    created(){
        this.getQuestionnaire();
    },
    methods:{
        async getQuestionnaire(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/reflection/questionnaire/detail?id=${this.id}&slug=${this.slug}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.questionnaire = res.data.data
                this.isLoad = false
            })
        },
    }
}
</script>